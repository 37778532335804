<template>
  <div class="content-container">
    <div class="add-team-member-form-con">
      <v-form
        @submit.prevent="addTeamMember"
        ref="entryForm"
        class="add-member-form"
      >
        <div class="add-team-member-form">
          <h3 class="form-title font-roboto fw-medium">Add Contact</h3>
          <div class="form-con">
            <!-- <v-form @submit.prevent="addTeamMember" ref="entryForm" class="add-member-form"> -->
            <v-row class="modal-row">
              <v-col class="modal-col" cols="12" sm="6" md="6">
                <v-text-field
                  label="First name*"
                  :rules="nameRules_firstname"
                  v-model="user.FirstName"
                  clearable
                  variant="underlined"
                  :clear-icon="'mdi-close'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="modal-col">
                <v-text-field
                  label="Last name*"
                  :rules="nameRules_lastname"
                  v-model="user.LastName"
                  clearable
                  variant="underlined"
                  :clear-icon="'mdi-close'"
                ></v-text-field>
              </v-col>

              <v-col class="modal-col" cols="12" sm="6" md="6">
                <v-text-field
                  class="phone-input"
                  ref="phoneInput"
                  label="Phone number*"
                  v-model="user.PhoneNumber"
                  :rules="phoneRules"
                  type="text"
                  @keypress="isPhoneNumber($event)"
                  clearable
                  variant="underlined"
                  :clear-icon="'mdi-close'"
                >
                  <vue-tel-input
                    :value="PhoneNumber1"
                    @open="CountrySelectOpen"
                    @close="CountrySelectClose"
                    :onlyCountries="selectedCountries"
                    :input-id="'phone'"
                    @input="onPhoneInput"
                  ></vue-tel-input>
                  <span class="countryCode" style="font-weight: 600"
                    >+{{ user.countryCode }}</span
                  >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="modal-col">
                <v-text-field
                  label="Email"
                  :rules="nameRules_email"
                  v-model="user.Email"
                  clearable
                  variant="underlined"
                  :clear-icon="'mdi-close'"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- </v-form> -->
          </div>
        </div>
        <div class="form-info-text-con d-flex gap-3">
          <div class="left-form-info">
            <h3>Notification</h3>
            <h3 class="fw-normal detail-text">
              Contacts receive a text message to go download the Sig2 mobile
              app.
            </h3>
          </div>
          <div class="text-center site-logo">
            <SiteLogoShadow />
          </div>
        </div>

        <div class="d-flex flex-column w-100">
          <div
            class="d-flex form-btns align-items-center justify-content-end w-100"
          >
            <v-btn
              :ripple="false"
              variant="plain"
              @click.prevent="resetUserForm()"
              class="font-roboto cancel-btn text-decoration-none text-uppercase"
            >
              Cancel
            </v-btn>
            <v-btn
              :ripple="false"
              :loading="loading"
              type="submit"
              variant="plain"
              class="btn btn-submit text-uppercase"
              >{{ this.userid ? "UPDATE USER" : "ADD CONTACT" }}</v-btn
            >or
            <button
              type="button"
              @click="showImportCsvModal = true"
              class="import_csv_btn   btn"
            >
              Import CSV
              <span class="arrow-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 11L12 6L7 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17 18L12 13L7 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </button>
            <ImportCsv
              @message="onCsvImportData"
              v-model="showImportCsvModal"
              @closed="showImportCsvModal = false"
            />
          </div>
        </div>
        <div
          class="w-100 success-alert-con"
          id="success-alert"
          v-if="success == 'Added'"
        >
          <div class="alert alert-dismissible alert-success">
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              @click="success = ''"
            >
              &times;
            </button>
            <span class="alert-headeing">Confirmation</span><br />
            Your contact was added and a text message notified them to download
            our mobile app.
          </div>
        </div>

        <div
          class="w-100 success-alert-con"
          id="success-alert"
          v-if="success == 'BulkAdded'"
        >
          <div class="alert alert-dismissible alert-success">
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              @click="success = ''"
            >
              &times;
            </button>
            <span class="alert-headeing">Confirmation</span><br />
            {{ success_message }}
          </div>
        </div>
      </v-form>
    </div>
    <div class="delete-alert-con" v-if="success == 'Deleted'">
      <div class="alert alert-dismissible alert-delete">
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          @click="success = ''"
          style="color: #828282"
        >
          &times;
        </button>
        <span class="alert-headeing">Confirmation</span><br />
        {{ deleteSuccessMsg }}
      </div>
    </div>
    <div class="selected_row_box" v-if="selectedContacts > 0 && !searchVisible">
      <div class="d-flex justify-content-center position-relative">
        <div class="bulk_select">
          <label>{{ selectedContacts }} Selected</label>
          <label class="mx-10 link" @click="deleteAll"
            ><DeleteIcon /><span>Delete</span>
          </label>
          <label class="mx-10 link" @click="confirmInviteAll = true"
            ><UserPlus /><span>Invite</span></label
          >
          <label v-if="sharingEnabled" class="mx-10 link" @click="confirmShareAll = true"
            ><ShareBatchIcon /><span>Share</span></label
          >
        </div>
      </div>
    </div>
    <!-- <div v-if="!noContacts" class="align-center justify-center"> -->
    <div class="align-center justify-center">
      <S2gTable
        :indeterminate="indeterminate"
        :items="users"
        :selectedItemCount="selectedContacts"
        ref="tableRef"
        :isWhiteIcons="true"
        :headers="contactheader"
        :searchText="search"
        :filterItems="[
          { label: 'All', value: 'all' },
          { label: 'None', value: 'none' },
          { label: 'Pending', value: 'pending' },
          { label: 'Verified', value: 'verified' },
          { label: 'Invite', value: 'invite' },
        ]"
        @column-filter="handleFilter"
        @toggle-row-select="selectMembers"
        :thsearch="false"
        :showCount="true"
        :isReload="true"
        @search="searchTeams"
        @open-search="searchOpen"
        @refresh="reset"
        :totalItems="`${pager.totalItems}`"
        :totalCountText="'Contacts'"
        :totalCountText1="'Contact'"
        :dynamicSearchHeight="false"
        :isSelectGlobalCheckBox="isSelectAll"
        @select-all="selectAll"
        :page="(currentPage - 1) * itemsPerPage"
      >
        <template v-slot:table_body="{ item }">
          
          <td>
            <span>{{ item.item.FirstName }} {{ item.item.LastName }}</span>
          </td>
          <td>
            <span>{{ item.item.PhoneNumber }}</span>
          </td>
          <!-- <td>
            <span>{{ item.item.Email }}</span>
          </td> -->
          <td>
            <!-- inviteStatus
                Status -->
            <span class="filter-cell" v-if="inviteStatus(item.item) == 'pending'"><ContactPending />Pending</span>
            <span class="filter-cell" v-if="inviteStatus(item.item) == 'verified'"><ContactVerified />Verified</span>
            <div class="filter-cell" v-if="inviteStatus(item.item) == 'invite'">
              <UserPlus />
                <button type="button" @click="onInviteAgain(item.item)">
                 <!-- Invite -->
                 <InviteButton />
                </button>
              
            </div>
          </td>
          
          <td>
            <div class="table-actions">
              <ShareListIcon v-if="item.item.Shared && sharingEnabled"/>
              <ContactSettingsPreview v-if="!item.item.DefaultContact" @contactShared="shareContact" class="setting-icon" value="true" :contactGroups="contactGroups" :contact="item.item" :sharingEnabled="sharingEnabled"/>   
              <span @click="editTeamMember(item.item)">
                <EditIcon class="icon-btn" />
              </span>
              <span @click="deleteTeamMember(item.item.UserID)">
                <DeleteIcon class="icon-btn" />
              </span>
            </div>
          </td>
        </template>
      </S2gTable>
    </div>
    <Paginaiton
      v-if="pager.totalPages > 1"
      :currentPage="currentPage"
      :totalItems="pager.totalItems"
      :itemsPerPage="itemsPerPage"
      :totalPages="pager.totalPages"
      @page-changed="changePage"
    />
    <!--  {{this.$root.userdetail.firsttimeuser}} -->
    <UpdateProfile
      v-if="popupTriggers.buttonTrigger && !firstlogin"
      :Receiver="editUser"
      :title="'Contact'"
      :TogglePopup="() => TogglePopup('buttonTrigger')"
      :ProfileSaved="(data) => userUpdated(data)"
    >
    </UpdateProfile>
    <!-- <UpdateProfile
      v-if="firstlogin"
      :title="'Operator'"
      :TogglePopup="() => userUpdated1(data)"
      :ProfileSaved="(data) => userUpdated1(data)"
      :Notification="'Please enter your phone number to preview and hear the messages you set, to make sure they will play correctly.'"
    >
    </UpdateProfile> -->
    <Confirmation
      v-model="showConfirmationModal"
      :data="confirmationData"
      @closed="showConfirmationModal = false"
      :onconfirm="(data) => deleteConfirmed(data)"
      :body="deleteMsg"
    />
    <Confirmation
      v-model="showBulkDeleteConfirmationModal"
      @closed="showBulkDeleteConfirmationModal = false"
      :onconfirm="(data) => deleteBulkConfirmed()"
      :body="deleteMsg"
    />

    <Confirmation
      v-model="confirmInviteAll"
      @closed="confirmInviteAll = false"
      :onconfirm="(data) => inviteAgainBulk()"
      :body="`<p class='mb-4'>Send invitations to download Sig2 to ${userStatusCount.invitedCount} Contacts.</p><p class='mb-4'>${userStatusCount.pendingCount} were already pending.</p><p>${userStatusCount.verifiedCount} have already verified their phone number.</p>`"
    />

    <Confirmation
      v-model="confirmShareAll"
      @closed="confirmShareAll = false"
      :onconfirm="(data) => shareAllBulk()"
      :body="`<p class='mb-4'>${selectedContacts} Contacts will be shared with ${totalOperators} Operators.</p><p class='mb-4'>This cannot be undone.</p>`"
    />

    <AlertDialog v-model="receiverFreeTrialError" @closed="receiverFreeTrialError = false" title="Attention">
    <div>During this period of free usage, you can add up to ten Receivers.</div> <br>
    <div>To add more than ten, activate your paid account now.</div> <br>
    <div>If you are not the Account Holder, request that they convert to a paid account.</div>
  </AlertDialog>
  </div>
</template>
<script>
const API_URL = process.env.VUE_APP_APIDOMAIN + "/teams";
const API_URL_BULK = process.env.VUE_APP_APIDOMAIN + "/teamsimport";
const API_URL_INVITE = process.env.VUE_APP_APIDOMAIN + "/inviteagain";
// console.log(API_URL);
import { VBtn, VIcon, VForm, VRow, VCol, VTextField } from "vuetify";
import UpdateProfile from "../UpdateProfile";
import ImportCsv from "../ImportCsv";
import Paginaiton from "./Paginaiton.vue";
import Confirmation from "../modals/Confirmation.vue";
import SiteLogoShadow from "@/components/icons/SiteLogoShadow.vue";
//import MembersTable from '@/components/common/MembersTable.vue'
import { ref } from "vue";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import ArrowIcon from "@/components/icons/ArrowIcon.vue";
import ArrowWhiteIcon from "@/components/icons/ArrowWhiteIcon.vue";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import S2gTable from "@/components/common/S2gTable.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import UserPlus from "@/components/icons/UserPlus.vue";
import ContactPending from "@/components/icons/ContactPending.vue";
import ContactVerified from "@/components/icons/ContactVerified.vue";
import InviteButton from "@/components/icons/InviteButton.vue";
import ShareListIcon from "@/components/icons/share/ShareListIcon.vue";
import ContactSettingsPreview from '@/components/common/ContactSettingsPreview.vue';
import ShareBatchIcon from "@/components/icons/share/ShareBatchIcon.vue";
//import ResetIcon from '@/components/icons/ResetIcon.vue';
import {
  manageUnSelectedItems,
  manageSelectedItems,
  manageSingleChecked,
  manageRowDefaultSelection,
} from "@/helpers/s2gtable-helper";
import AlertDialog from "../modals/AlertDialog.vue";
export default {
  name: "teams",
  setup() {
    const success = ref("");

    const popupTriggers = ref({
      buttonTrigger: false,
    });
    const TogglePopup = (trigger, user) => {
      popupTriggers.value[trigger] = !popupTriggers.value[trigger];
    };
    const removeSuccessAlert = () => {
      success.value = "";
    };
    return {
      UpdateProfile,
      popupTriggers,
      TogglePopup,
      removeSuccessAlert,
    };
  },
  components: {
    VBtn,
    SiteLogoShadow,
    /* MembersTable, */ UpdateProfile,
    Confirmation,
    Paginaiton,
    VForm,
    VRow,
    VCol,
    VTextField,
    VueTelInput,
    ImportCsv,
    S2gTable,
    DeleteIcon,
    EditIcon,
    ContactPending,
    ContactVerified,
    UserPlus,
    InviteButton,
    ShareListIcon,
    ContactSettingsPreview,
    ShareBatchIcon,
    AlertDialog
  },
  async created(){
      const response = await fetch('countryCodes.json');
      const data = await response.json();
      this.selectedCountries = data.selectedCountries;
  },
  data: () => ({
    pager: {},
    serverError: "",
    currentPage: 1,
    receiverFreeTrialError: false,
    itemsPerPage: 10,
    error: "",
    firstnameError: "",
    lastnameError: "",
    phonenumberError: "",
    PhoneNumber1: "",
    isSelectAll: false,
    selectedContacts: [],
    search: "",
    sort: "DefaultContact desc ,FirstName",
    order: "asc",
    userid: 0,
    currentSort: "name",
    currentSortOrder: "asc",
    users: [],
    user: {},
    contactGroups:[],
    iso2:"",
    editUser: {},
    success: "",
    showConfirmationModal: false,
    showBulkDeleteConfirmationModal: false,
    confirmationData: {},
    userStatusCount: {},
    editableContactsCoun: 0,
    sharingEnabled:true,
    totalOperators:0,
    formsubmitwait: false,
    firstlogin: false,
    token: localStorage.getItem("tknds") || "",
    files: [],
    lastInputMethod: null,
    showImportCsvModal: false,
    searchVisible: false,
    uncheckedMembers: [],
    checkedMember: [],
    nameRules_firstname: [(v) => !!v || "First name is required"],
    nameRules_lastname: [(v) => !!v || "Last name is required"],
    nameRules_email: [
      /* (v) => !!v || "Email field is required", */
      (v) => !v || /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    selectedCountries: [],
    contactheader: [
      { key: "name", title: "Name", class: "pr-0" },
      { key: "phone", title: "Phone number", class: "pr-0" },
      // { key: "email", title: "Email", class: "pr-0" },
      {
        key: "status",
        title: "Status",
        class: "pr-0",
        isFilter: true,
        filterItems: [
          { label: "Verified", value: "verify" },
          { label: "Pending", value: "pending" },
          { label: "Invite", value: "invite" },
        ],
      },
      { key: "detail", title: "Details" },
    ],
    clearTimeout: false,
    indeterminate: false,
    loading: false,
    confirmInviteAll: false,
    confirmShareAll:false,
    statusFilter: "",
    selectAllFilter: "",
    deleteMsg: "Do you really want to delete this contact?",
    selectedContactIds: [],
    deleteSuccessMsg:
      "A contact has been deleted. They cannot receive any more geofenced messages from you.",
  }),
  computed: {
   phoneRules() {
      return [
        v => !!v || 'Phone number is required',
        v => this.validatePhoneNumber(v)
      ];
    }
    },
  mounted() {
    window.pageTitle = "Teams";
    this.user.Locale = "English";
    if (this.$root.userdetail.firsttimeuser) {
      localStorage.setItem("justLoggedIn", "false");
      //this.popupTriggers.buttonTrigger = this.$root.userdetail.firsttimeuser;
    }
    //console.log("======", this.isSelectAll);

    this.firstlogin = this.$root.userdetail.firsttimeuser;
    this.user.ClientID = this.$root.userdetail.user[0].ClientID;
    this.user.CreatedBy = this.$root.userdetail.user[0].UserID;
    this.userid = this.$route.query.userid ? this.$route.query.userid : 0;
    if (this.userid) {
      this.getQueryData(this.userid);
    }
  },
  methods: {
 /*    contactGroups(contactId){
      console.log('contactId = ', contactId);
      return contactId;
    }, */
    searchOpen(e) {
      this.searchVisible = true;
    },
    searchTeams(e) {
      this.reset();
      this.search = e;
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: this.currentPage,
          perpage: this.itemsPerPage,
          status: this.statusFilter,
          search: this.search,
          selectAllSort: this.selectAllFilter,
        }),
      });
    },
    isPhoneNumber(evt) {
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
			const keyPressed = evt.key;
			
			if (!keysAllowed.includes(keyPressed) || evt.target.value.length > 9) {
				evt.preventDefault()
			}
		},
    validatePhoneNumber(v) {
      //console.log(this.user)
      //return this.user.countryCode+ this.user.PhoneNumber+'correct phone number '+ this.iso2;
      let phoneNumber = "";
      if (v.indexOf("+") == -1){
        phoneNumber = parsePhoneNumberFromString(this.user.countryCode+ this.user.PhoneNumber, this.iso2);
      } else {
        phoneNumber = parsePhoneNumberFromString(v);
      }
      if (phoneNumber && phoneNumber.isValid()) {
        return true;
      } else {
        return 'Phone Number is invalid';
      }
    },
    truncateText(text, length){     
        if (!text){return text}
        return 'Char count ' +String(text).length;
        //if (String(text).length < length){ return text }
        //return String(text).substring(0, length) + '...';
    },
    orderBy(col) {
      if (this.sort == col && this.order == "ASC") {
        this.order = "DESC";
      } else {
        this.order = "ASC";
      }
      this.sort = col;

      let query = Object.assign({}, this.$route.query);
      delete query.sort;
      delete query.order;
      this.$router.replace({ query });
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          order: this.order,
          sort: this.sort,
        }),
      });
    },
    userUpdated(data) {
      this.users.map((u) => {
        if (u.UserID == data.UserID) {
          u.FirstName = data.FirstName;
          u.Email = data.Email;
          u.CreatedBy = this.$root.userdetail.user[0].UserID;
          u.LastName = data.LastName;
          u.PhoneNumber = data.PhoneNumber;
          u.PhoneNumber = data.PhoneNumber;
        }
        return u;
      });
      this.popupTriggers.buttonTrigger = false;
    },
    userUpdated1(data) {
      this.$root.userdetail.firsttimeuser = false;
      this.firstlogin = false;
      //alert('this.$root.userdetail.firsttimeuser :: '+ this.$root.userdetail.firsttimeuser)
    },
    async addTeamMember(event, todelete = false) {
      //If the form submit is already in progress, do not submit it again
      if (this.formsubmitwait) {
        return false;
      }

      this.user.ClientID = this.$root.userdetail.user[0].ClientID;
      this.user.CreatedBy = this.$root.userdetail.user[0].UserID;
      let data = todelete ? todelete : this.user;
      let action = todelete ? "Deleted" : "Added";
      action = this.userid ? "Updated" : action;
      this.user.Operator =
        this.$root.userdetail.user[0].FirstName +
        " " +
        this.$root.userdetail.user[0].LastName;
      let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      this.user.InvitedAt = new Date(Date.now() - tzoffset)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      let formValid = await this.$refs.entryForm.validate();
      let valid = action != "Deleted" ? formValid.valid : true;

      if (!valid) {
        return false;
      }
      this.formsubmitwait = true;
      this.loading = true;
      console.log("Data ::: ", data);
      //this.$refs.entryForm.reset()
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          this.loading = false;
          if(result?.type=='FREE_TRIAL'){
            if(result?.code=='recievers') this.receiverFreeTrialError = true;
            return;
          }

          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.serverError = result.message;
            this.$root.logout();
          }

          this.formsubmitwait = false;
          if (result.details) {
            // there was an error...
            const error = result.details
              .map((detail) => detail.message)
              .join(". ");
            this.error = error;
            setTimeout(() => (this.success = ""), 5000);
          } else {
            this.error = "";
            this.deleteSuccessMsg =
              "A contact has been deleted. They cannot receive any more geofenced messages from you.";
            this.success = action;

            this.resetUserForm();
            //Show refreshed data;
            this.getQueryData();
            setTimeout(() => (this.success = ""), 5000);
          }
        }).catch(error=>{
          if(error?.type=='FREE_TRIAL'){
            if(error?.code=='recievers') this.receiverFreeTrialError = true;
          }
        });
    },
    onInviteAgain(user) {
      let parsePhone = this.extractCountryCode(user.PhoneNumber);
      user.Operator =
        this.$root.userdetail.user[0].FirstName +
        " " +
        this.$root.userdetail.user[0].LastName;
      user.ClientID = this.$root.userdetail.user[0].ClientID;
      user.LocalNumber = parsePhone.nationalNumber;
      user.countryCode = parsePhone.countryCallingCode;
      let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      user.InvitedAt = new Date(Date.now() - tzoffset)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      console.log("invite again", user);
      fetch(API_URL_INVITE, {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.serverError = result.message;
            this.$root.logout();
          }

          this.formsubmitwait = false;
          if (result.details) {
            // there was an error...
            const error = result.details
              .map((detail) => detail.message)
              .join(". ");
            this.error = error;
            setTimeout(() => (this.success = ""), 5000);
          } else {
            this.error = "";
            this.success = "BulkAdded";
            this.success_message = "Contact Invited Again";
            //this.resetUserForm();
            //Show refreshed data;
            this.getQueryData();
            setTimeout(() => (this.success = ""), 5000);
          }
        });
    },
    onCsvImportData(data) {
     // alert('sss')
      console.log('data to parent :: ', data);
      let phoneNumberError = ""; // Reset error message
      let failCount = 0;
      let contactData = [];
      data.forEach((element) => {
        let validated = true;
        //validate all phone rules
        for (const rule of this.phoneRules) {
          const result = rule("+"+element.phoneNumber);
          if (typeof result === "string") {
            element.error = result;
            validated = false;
          }
        }
        if (validated) {
          let parsePhone = this.extractCountryCode(element.phoneNumber);
          element.Operator =
            this.$root.userdetail.user[0].FirstName +
            " " +
            this.$root.userdetail.user[0].LastName;
          element.ClientID = this.$root.userdetail.user[0].ClientID;
          //alert(this.$root.userdetail.user[0].UserID)
          element.CreatedBy = this.$root.userdetail.user[0].UserID;
          element.Locale = "English";
          element.LocalNumber = parsePhone.nationalNumber;
          element.countryCode = parsePhone.countryCallingCode;
          let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
          element.InvitedAt = new Date(Date.now() - tzoffset)
            .toISOString()
            .slice(0, 19)
            .replace("T", " ");
          contactData.push(element);
        } else {
          failCount++;
        }
      });
      phoneNumberError =
        data.length -
        failCount +
        " Successfully Imported and " +
        failCount +
        " failed to import";
      //console.log('contactData :: ', contactData, 'phoneNumberError ::', phoneNumberError);
      fetch(API_URL_BULK, {
        method: "POST",
        body: JSON.stringify(contactData),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.serverError = result.message;
            this.$root.logout();
          }

          this.formsubmitwait = false;
          if (result.details) {
            // there was an error...
            const error = result.details
              .map((detail) => detail.message)
              .join(". ");
            this.error = error;
            setTimeout(() => (this.success = ""), 5000);
          } else {
            this.error = "";
            this.success = "BulkAdded";
            this.success_message = phoneNumberError;
            //this.resetUserForm();
            //Show refreshed data;
            this.getQueryData();
            setTimeout(() => (this.success = ""), 5000);
          }
        });
    },
    editTeamMember(user) {
      this.editUser = user;
      this.TogglePopup("buttonTrigger");
    },
    toggleRowChecked(e) {
      const event = e.e;
      if (this.isSelectAll && !event.target.checked) {
        this.indeterminate = true;
      } else {
        const u = this.groups.every((r) => r.checked);
        console.log(u);
        this.indeterminate = false;
      }
    },
    selectAll(event) {
      this.searchVisible = false;
      this.isSelectAll = event.target.checked;
      //console.log('uutyyu',this.isSelectAll, event.target.checked)
      let disabled_items = 0;
      this.users = this.users.map((e) => {
        e.checked = !e.disable_checkbox && event.target.checked;
        if(e.disable_checkbox){
          disabled_items ++;
        }
         
        e.checkdClass = "";
        e.class = "hover_item";
        e.checkdClass = e.checked ? "member_selected" : "";
        return e;
      });
      //console.log('ppp',this.users)
      this.uncheckedMembers = [];
      this.checkedMembers = [];
      if (this.isSelectAll) {
        //this.selectedContacts = this.pager.totalItems - disabled_items;
        this.selectedContacts = this.editableContactsCoun;
        this.checkedContacts = [];
        this.uncheckedContacts = [];
        this.indeterminate = false;
      } else {
        this.selectedContacts = 0;
        this.indeterminate = false;
      }
    },
    resetUserForm() {
      this.userid = 0;
      this.user.FirstName = null;
      this.user.LastName = null;
      this.user.Email = null;
      this.user.PhoneNumber = null;
      this.firstnameError = this.lastnameError = this.phonenumberError = "";
      this.$refs.entryForm.reset();
    },

    deleteTeamMember(id) {
      //if(!confirm("Do you really want to delete the team member?")){ return;}
      //this.addTeamMember(null, {deleteRow: id})
      this.showConfirmationModal = true;
      this.confirmationData = { id: id };
    },
    deleteConfirmed(data) {
      console.log("DeleteConfirm ::", data);
      this.showConfirmationModal = false;
      this.addTeamMember(null, { deleteRow: data.id });
      //this.getQueryData();
    },
    inviteStatus(user) {
      if (user.pending){
        return "pending";
      } else if(user.verified){
        return "verified";
      } else if (user.invite){
        return "invite";
      }
      //console.log("user   = ", user);
      /* if (user.invitestatus) {
        return "verified";
      }

      if (user.InvitedAt) {
        let inviteTime = new Date(user.InvitedAt).getTime();
        //12 hrs = 43200000 ms
        if (new Date().getTime() - inviteTime < 43200000) {
          return "pending";
        } else if (new Date().getTime() - inviteTime > 43200000) {
          return "invite";
        }
      } */
      return "";
    },
    getQueryData(userid = 0) {
      console.log(
        "getQueryData",
        this.selectedContactIds,
        this.isSelectAll,
        this.selectedContactIds
      );
      var search = this.$route.query.search || "";
      var status = this.$route.query.status || "";
      this.selectAllFilter = this.$route.query.selectAllSort || "";
      var page = parseInt(this.$route.query.page) || 1;
      this.currentPage = page;
      //this.$refs.tableRef.searchInput.value = search
      // console.log('Client ID = ', this.$root.userdetail.user[0])
      var url = API_URL + "?page=" + page + "&perpage=" + this.itemsPerPage;
      url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
      url += "&Role=" + this.$root.userdetail.user[0].Role;
      url += "&CreatedBy=" + this.$root.userdetail.user[0].UserID;
      if (search != "") {
        this.search = search;
        url += "&search=" + search;
      }
      if (status != "") {
        url += "&status=" + status;
      }

      if (this.selectAllFilter != "") {
        url += "&sort=" + this.selectAllFilter;
        url += "&order=" + this.order;
      } else if (this.sort) {
        url += "&sort=" + this.sort;
        url += "&order=" + this.order;
      }
      if (userid) {
        url += "&id=" + userid;
      }
      console.log("ULR ::", url);
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //Auth Token Failed
          //console.log("response ::", result)
          if (result.AuthSuccess == 0) {
            this.serverError = result.message;
            this.$root.logout();
          } else {
            if (userid) {
              this.user = result.team;
            } else {
              console.log('result Contacts :: ', result)
              this.userStatusCount = result.teams.count;
              this.editableContactsCoun = result.teams.editableContactsCoun;
              this.totalOperators = result.teams.totalOperators;
              this.sharingEnabled = result.teams.sharingEnabled;
              this.contactGroups = result.teams.contactGroups;

              this.users = result.teams.docs?.map((e) => {
                e.checked = this.isSelectAll;
                if (this.selectedContactIds.indexOf(e.UserID) > -1) {
                  e.checked = true;
                }

                if (this.uncheckedMembers.some((o) => o.UserID == e.UserID)) {
                  e.checked = false;
                }
                if ((e.Shared && e.CreatedBy != this.$root.userdetail.user[0].UserID) || e.DefaultContact){
                  e.checked = false;
                }
                e.class = "hover_item";
                e.checkdClass = e.checked ? "member_selected" : "";
                if (e.DefaultContact || (e.Shared && e.CreatedBy != this.$root.userdetail.user[0].UserID)){
                  e.disable_checkbox = true;
                }
                return e;
              });

              
              this.manageSelection();

              this.pager = result.teams.pager;
            }
          }
        });
    },
    reset() {
      this.statusFilter = "";
      this.selectAllFilter = "";
      this.search = "";
      this.selectedContacts = 0;

      this.isSelectAll = false;
      this.indeterminate = false;
      this.selectedContactIds = [];
      this.currentPage = 1;
      this.users = this.users.map((e) => {
        e.checked = false;
        e.checkdClass = "";
        return e;
      });
      console.log("-", this.selectAllFilter);
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: this.currentPage,
          perpage: this.itemsPerPage,
          status: this.statusFilter,
          search: this.search,
          selectAllSort: this.selectAllFilter,
        }),
      });
      //this.getQueryData();
    },
    changePage(page) {
      console.log(this.selectAllFilter);
      if (page != this.currentPage) {
        this.currentPage = page;
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            page: this.currentPage,
            perpage: this.itemsPerPage,
            status: this.statusFilter,
            selectAllSort: this.selectAllFilter,
          }),
        });
      }
    },

    onPhoneInput(phone, phoneObject, input) {
      this.iso2 = phoneObject.country.iso2;
      this.user.countryCode = phoneObject.country.dialCode;
    },
    extractCountryCode(number) {
      // console.log('number ::', number)
      const phoneNumber = parsePhoneNumberFromString("+" + number);
      if (phoneNumber) {
        return phoneNumber;
      } else {
        return "invalid"; // Handle invalid phone number
      }
    },
    CountrySelectOpen() {
      document.querySelector(
        ".phone-input .v-input__details .v-messages"
      ).style.display = "none";
    },
    CountrySelectClose() {
      document.querySelector(
        ".phone-input .v-input__details .v-messages"
      ).style.display = "block";
    },
    handleFilter(e) {
      if (e.column) {
        this.statusFilter = e.e.value;
        this.currentPage = 1;
        this.isSelectAll = false;
        this.indeterminate = false;
        this.selectedContacts = 0;
        this.selectedContactIds = [];
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            page: this.currentPage,
            perpage: this.itemsPerPage,
            status: this.statusFilter,
          }),
        });
      } else {
        this.selectAllFilter = e.e.value;
        if (e.e.value != "none" && e.e.value != "all") {
          this.currentPage = 1;
          this.selectAll({ target: { checked: false } });

          this.manageSelection();
        } else {
          if (e.e.value == "none") {
            this.isSelectAll = false;
            this.selectedContacts = 0;
            this.selectedContactIds = [];
            this.selectAll({ target: { checked: false } });
            this.manageSelection();
          }
          this.selectAllFilter = e.e.value;
          this.currentPage = 1;
        }
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            page: this.currentPage,
            perpage: this.itemsPerPage,
            status: this.statusFilter,
            search: this.search,
            selectAllSort: this.selectAllFilter,
          }),
        });
        //this.getQueryData();
        // this.$router.push({ query: Object.assign({}, this.$route.query, { page: this.currentPage , perpage: this.itemsPerPage, status:this.statusFilter, selectAllSort:this.selectAllFilter}) });
        // this.getQueryData()

        //
      }
    },
    manageSelection() {
      this.selectedContactIds = [];
      let disabled_items = 0;
      this.users = this.users.map((e) => {
        if (e.disable_checkbox){
          disabled_items ++;
        }
        if (this.selectAllFilter == "all") {
          e.checked = true;
          this.isSelectAll = true;
          this.selectAll({ target: { checked: true } });
        } else if (this.selectAllFilter == "none") {
          e.checked = false;
        } else if (this.selectAllFilter == "pending" && e.pending) {
          this.selectedContacts = this.userStatusCount.pendingCount - disabled_items;
          e.checked = e.disable_checkbox ? false : true;
        } else if (this.selectAllFilter == "invite" && e.invite) {
          this.selectedContacts = this.userStatusCount.invitedCount - disabled_items;
          e.checked = e.disable_checkbox ? false : true;
        } else if (this.selectAllFilter == "verified" && e.verified) {
          e.checked = e.disable_checkbox ? false : true;
          this.selectedContacts = this.userStatusCount.verifiedCount - disabled_items;
        }
        e.class = "hover_item";
        e.checkdClass = e.checked ? "member_selected" : "";

        /* console.log('eeeee', e)
        e.checked = this.isSelectAll;
        let inviteTime = new Date(e.InvitedAt).getTime();
        const date = new Date().getTime() - inviteTime;
        // if(this.selectAllFilter =='pending'){
        // }
        if (this.selectAllFilter == "all") {
          e.checked = true;
 
          this.isSelectAll = true;
          this.selectAll({ target: { checked: true } });
        } else if (this.selectAllFilter == "none") {
          e.checked = false;
        } else if (this.selectAllFilter == "pending" && date < 43200000 && !e.invitestatus) {
          this.selectedContacts = this.userStatusCount.pendingCount;
          e.checked = true;
        } else if (
          e.InvitedAt &&
          this.selectAllFilter == "invite" &&
          date > 43200000 && !e.invitestatus
        ) {
          e.checked = true; 
          this.selectedContacts = this.userStatusCount.invitedCount;

        } else if (this.selectAllFilter == "verified" && e.invitestatus) {
          e.checked = true;
          this.selectedContacts = this.userStatusCount.verifiedCount;
        }
        if (this.selectedContactIds.indexOf(e.UserID) > -1) {
          e.checked = true;
        }
        if (this.uncheckedMembers.some((o) => o.UserID == e.UserID)) {
          e.checked = false;
        }
        e.class = "hover_item";
        e.checkdClass = e.checked ? "member_selected" : ""; */
        
        return e;
      }
    
    );


      this.manageGlobalSelection();
       if(this.selectAllFilter=='invite'){
         //this.users.sort((a, b) => (a.inviteStatus ? -1 : 1));
        }
        //alert('hi')
        //this.selectedContacts = this.users.filter(e => e.checked).length;
    },
    selectMembers(e) {
      const event = e.e;
      this.indeterminate = false;
      this.searchVisible = false;
      this.manageSelectedContactIds(e.item.UserID, event.target.checked);
      const u = manageSingleChecked(
        event,
        e.item,
        "UserID",
        this.users,
        this.selectedContacts,
        this.isSelectAll,
        this.pager.totalItems
      );
      //console.log(u);
      this.selectedContacts = Number(u.selectedCounts);
      this.isSelectAll = u.isSelectAll;
      this.indeterminate = u.indeterminate;
      if (event.target.checked) {
        e.item.checked = event.target.checked;
      } else {
        // if (this.isSelectAll) {
        //   this.indeterminate = true;
        // }
      }

      manageSelectedItems(event, e.item, "UserID", this.checkedMember);
      manageUnSelectedItems(event, e.item, "UserID", this.uncheckedMembers);
      console.log(this.checkedMember);
      this.manageGlobalSelection();
      this.users = this.users.map((e) => {
        e.checkdClass = "";
        if (e.checked) {
          e.checkdClass = "member_selected";
        }
        e.class = "hover_item";
        return e;
      });
    },
    manageSelectedContactIds(id, checked) {
      if (checked) {
        this.selectedContactIds.push(id);
      } else {
        const index = this.selectedContactIds.indexOf(id);
        if (index > -1) {
          this.selectedContactIds.splice(index, 1);
        }
      }
    },
    manageGlobalSelection() {
      //const checkedCount = this.users.filter((e) => e.checked).length;

      if (this.selectedContacts > 0) {
        if (this.selectedContacts == this.pager.totalItems) {
          this.isSelectAll = true;
          this.selectedContacts = this.pager.totalItems;
          this.indeterminate = false;
        } else if (!this.isSelectAll) {
          this.indeterminate = true;
        }
      } else {
        this.indeterminate = false;
        this.isSelectAll = false;
      }
    },
    deleteBulkConfirmed() {
      var url = API_URL;
      const req = {
        isAllSelected: this.isSelectAll,
        isGlobalFilter: this.selectAllFilter,
        // selectedContact: this.selectedContactIds,
        selectedContact: this.checkedMember.map((e) => e.UserID),
        unselectedContact: this.uncheckedMembers.map((e) => e.UserID),
        ClientID: this.$root.userdetail.user[0].ClientID,
        Role : this.$root.userdetail.user[0].Role,
        CreatedBy : this.$root.userdetail.user[0].UserID
      };

      console.log('req to delete ==== ', req);

      fetch(url, {
        method: "DELETE",
        body: JSON.stringify(req),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          this.showBulkDeleteConfirmationModal = false;
          this.deleteSuccessMsg = `${this.selectedContacts} contacts has been deleted. They cannot receive any more geofenced messages from you.`;
          this.success = "Deleted";
          setTimeout(() => (this.success = ""), 5000);

          this.reset();
        });
    },
    shareContact(contact){
      var url = process.env.VUE_APP_APIDOMAIN + "/sharecontact";
      const req = {
        UserID: contact.UserID,
        ClientID: this.$root.userdetail.user[0].ClientID,
        Role : this.$root.userdetail.user[0].Role,
        CreatedBy : this.$root.userdetail.user[0].UserID,
        Operator : this.$root.userdetail.user[0].FirstName + " " + this.$root.userdetail.user[0].LastName,
      };
      fetch(url, {
        method: "POST",
        body: JSON.stringify(req),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          this.confirmShareAll = false;
          //this.success = "BulkAdded";
          this.success_message = "Contacts Shared";
          setTimeout(() => (this.success = ""), 5000);
          this.getQueryData();
          this.reset();
        });
    },
    shareAllBulk(){
      var url = process.env.VUE_APP_APIDOMAIN + "/bulkshare";
      const req = {
        isAllSelected: this.isSelectAll,
        isGlobalFilter: this.selectAllFilter,
        selectedContact: this.checkedMember.map((e) => e.UserID),
        unselectedContact: this.uncheckedMembers.map((e) => e.UserID),
        ClientID: this.$root.userdetail.user[0].ClientID,
        Role : this.$root.userdetail.user[0].Role,
        CreatedBy : this.$root.userdetail.user[0].UserID,
        Operator : this.$root.userdetail.user[0].FirstName + " " + this.$root.userdetail.user[0].LastName,
      };
      fetch(url, {
        method: "POST",
        body: JSON.stringify(req),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          this.confirmShareAll = false;
          //this.success = "BulkAdded";
          this.success_message =
            this.selectedContacts + " Contacts Shared";
          setTimeout(() => (this.success = ""), 5000);
          this.getQueryData();
          this.reset();
        });
    } 
    ,
    inviteAgainBulk() {
      var url = process.env.VUE_APP_APIDOMAIN + "/bulkinvite";
      let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      const req = {
        isAllSelected: this.isSelectAll,
        isGlobalFilter: this.selectAllFilter,
        // selectedContact: this.selectedContactIds,
        selectedContact: this.checkedMember.map((e) => e.UserID),
        unselectedContact: this.uncheckedMembers.map((e) => e.UserID),
        ClientID: this.$root.userdetail.user[0].ClientID,
        Role : this.$root.userdetail.user[0].Role,
        CreatedBy : this.$root.userdetail.user[0].UserID,
        Operator :
        this.$root.userdetail.user[0].FirstName +
        " " +
        this.$root.userdetail.user[0].LastName,
        inviteAt: new Date(Date.now() - tzoffset)
          .toISOString()
          .slice(0, 19)
          .replace("T", " "),
      };
      fetch(url, {
        method: "POST",
        body: JSON.stringify(req),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          this.confirmInviteAll = false;
          this.success = "BulkAdded";
          this.success_message =
            this.selectedContacts + " Contacts Invited Again";
          setTimeout(() => (this.success = ""), 5000);

          this.reset();
        });
    },
    deleteAll() {
      this.deleteMsg = `Are you sure want to delete ${this.selectedContacts} contacts.`;
      this.showBulkDeleteConfirmationModal = true;
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.getQueryData();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.invite-button {
  padding: 1px 15px;
  color: white;
  background-color: #7b8794;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
}
.btn.btn-submit {
  width: 140px;
  height: 40px;
  border-radius: 5px;
}
 
 
.servererror {
  width: 100%;
  background: red;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
}
::v-deep .phone-input .v-field-label:not(.v-field-label--floating) {
  margin-top: -20px;
}
.add-team-member-form-con {
  border-radius: 20px;
  background: #fff;
  padding: 33px 30px 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 88px;
  margin-bottom: 20px;
  .form-title {
    color: #000;
    font-size: 16px;
    font-style: normal;
    line-height: 100%;
    margin-bottom: 31px
  }
  .add-team-member-form {
    flex: 1;
  }
  .form-info-text-con {
    width: 340px;
    padding-left: 9px;
    margin-left: auto;
    & .left-form-info {
      position: relative;
      left: -5px;
    }
  }

  @media screen and (max-width: 992px) {
    gap: 40px;
    .add-team-member-form,
    .form-info-text-con {
      width: 100%;
    }
  }
  @media screen and (max-width: 568px) {
    padding: 30px 15px 20px;
    margin-bottom: 20px;
  }
}
::v-deep .v-input.error--text {
  color: #b10000 !important;
  caret-color: #b10000 !important;
  .v-input__control {
    .v-text-field__details > .error--text {
      color: #b10000 !important;
    }
    .v-input__slot {
      &:before {
        border-width: 2px 0 0;
      }
      .v-text-field__slot > .error--text {
        color: #b10000 !important;
      }
      .v-input__append-inner > .v-input__icon--clear > .error--text {
        color: #b10000 !important;
        caret-color: #b10000 !important;
      }
    }
  }
}

.add-member-form {
  display: flex;
  flex-wrap: wrap;
  column-gap: 88px;
  row-gap: 30px;
  width: 100%;
  .form-control-con {
    width: calc(50% - 20px);
    .form-label,
    .form-control {
      padding-left: 16px;
      width: 100%;
      @media screen and (max-width: 568px) {
        padding-left: 0;
      }
    }
    .form-control {
      &.invalid {
        border-bottom: 2px solid #ff5252;
      }
      &:focus {
        border-bottom: 2px solid #1976d2;
        caret-color: #1976d2;
      }
    }
    .form-label {
      &.invalid {
        color: #ff5252;
      }
    }
    &:focus-within .form-label {
      color: #1976d2;
    }
    &.disabled {
      opacity: 0.5 !important;
      pointer-events: none !important;
      background:none !important;
      color:#1e88e5!important;

    }
    @media screen and (max-width: 568px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 568px) {
    margin-top: 30px;
    row-gap: 30px;
  }
}
.form-info-text-con {
  h3 {
    color: #000;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 31px;
    &.detail-text {
      line-height: 125%;
    }
    @media screen and (max-width: 568px) {
      margin-bottom: 24px;
    }
  }
}
.invalid-feedback {
  /* color: #49454F; */
  color: #ff5252;
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  margin-top: 4px;
  padding-left: 16px;
  z-index: 2;
  display: block;
  @media screen and (max-width: 568px) {
    padding-left: 0;
  }
}
.form-btns {
  margin-top: -10px;
  gap: 20px;
  color: #828282;
  @media screen and (max-width: 568px) {
    margin-top: 0;
  }
  .import_csv_btn {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    font-weight: 500;
    height: 40px !important;
    border-radius: 5px;
    width: 140px;
    border: 1px solid var(--Gray-1, #333) ;
    background: var(--Sig2-White, #fff);
    justify-content: center;
    color: #333333;
    padding: 0 9px 0px 19px;
    .arrow-icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      border: 1px solid #6B87D4;
      background: #6B87D4;
      color: #fff;
      .black_icon {
        display: none;
      }
      .white_icon {
        display: block;
      }
    }
  }
}
.cancel-btn {
  color: #828282 !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.success-alert-con,
.delete-alert-con {
  // margin-top: 30px;
  width: 100%;
  .alert {
    padding: 10px 16px 15px;
  }
}
.delete-alert-con {
  margin: 0 auto 26px;
}
.alert-dismissible {
  border-radius: 10px;
  border: 0;
  color: #333;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
  &.alert-success {
    background: #d6fcc4;
  }
  &.alert-delete {
    background: #f5e0ab;
    margin-bottom: 30px;
  }
  .alert-headeing {
    line-height: 155%;
    font-size: 20px;
  }
}
.alert-dismissible .close {
  padding: 0;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.md-field {
  margin-bottom: 0;
}
.csv-import {
  margin-top: 20px;
}
.drop-area {
  width: 200px;
  height: 100px;
  border: 2px dashed #ccc;
  text-align: center;
  line-height: 100px;
  margin-bottom: 10px;
}
::v-deep {
  .selected-item td {
    background: #eaf2fc;
  }
}
.link {
  color: #4783e6;
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 6px;
  span{
    cursor: pointer;
  }
   
}
.selected_row_box {
  margin-bottom: 10px;
}
.bulk_select {
  display: flex;
  justify-content: space-between;
  padding-left: 19px;
  padding-right: 19px;
  // padding-top: 10px;
  align-items: center;
  // padding-bottom: 14px;
  background: #fff;
  border-radius: 8px;
  width: 606px;
  height: 44px;
  top: 12px;
  label {
    font-weight: 500;
  }
}
.modal-col-r {
  padding-right: 20px;
}
.modal-col-l {
  padding-left: 20px;
}

.white_icon {
  display: none;
}

.site-logo {
    position: relative;
    left: 10px;
}

</style>