<template>
    <v-dialog class="dialog" v-model="dialog"  persistent  max-width="446">
        <v-card>
            <v-toolbar class="modal-header">
                <v-toolbar-title>Alert to the Admin</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="card-text-con">
                <div class="modal-text">
                    <div>There must be at least one Admin.</div> <br />
                    <div>First add a New User and assign the role of Admin before you disable yourself as Admin.</div> <br />
                    <div>After a New User has signed in as an Admin, your role as Admin can be disabled.</div>
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
                <button @click="close" class="close-btn text-btn btn ms-auto">Close</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { VDialog, VCard, VCardText, VCardActions } from 'vuetify'

export default {
    name: 'OneAdminAlert',
    props: ['value','close'],
    components : {
        VDialog, VCard, VCardText, VCardActions  
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
    }
};
</script>
<style lang="scss" >
    .actions {
        padding: 18px !important;
        flex-direction: row-reverse;
    }
    .card-text-con.wishlist-modal{
        padding: 18px 18px 0 !important;
         .wishlist-modal-text{
            color: #000;
            font-family: 'Roboto';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 166.667%;
            span{
                font-size: 20px;
                line-height: 30px;
            }
         }
    }
    .v-dialog>.v-card>.v-card__actions.whishlist-actions{
        padding: 0 27px 18px 18px !important;
        margin-top: 40px;
    }
    .btn-whishlist{
        padding: 9px 17px !important;
        border-radius: 3px !important;
        background: #34A853 !important;
        color: #fff !important;
        font-family: "Roboto" !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
        &:hover{
            background: #48c56a !important;
        }
    }
    .whishlist-actions .close-btn{
        color: #4F4F4F !important;
    }

</style>