<template>
	<div class="message-groups form-elements">
		<div class="form-group" style="width: 100%;">
			<!-- Duration -->
			<label class="form-label mb-0">Receivers</label>
			<div class="form-control static-control">
				{{selectedGroupsCount}} Selected
				<button class="edit-icon-button" type="button" @click="showEditModal"><EditIcon/></button>
        </div>
			<MessageGroupAssignModal 
			:showSettings="showAboutModal"
			:isSelectAllGroups = "isSelectAllGroups"
			:selectedGroupsIds = "selectedGroupsIds"
			:groupTypesList = "groupTypesList"
			:isSelectAllContacts = "isSelectAllContacts"
			:selectedContactIds = "selectedContactIds"
			@closed="showAboutModal = false" 
			@group-selection-saved="onSelectionSaved"
			/> 
		</div>
	</div>
</template>

<script setup>
	const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
	import {computed, defineProps, ref, defineEmits, onMounted, getCurrentInstance } from 'vue';
	import { useRoute } from 'vue-router';
	import EditIcon from './icons/EditIcon';
	import MessageGroupAssignModal from './modals/MessageGroupAssignModal';
	let showAboutModal = ref(false);
	let selectedGroupsCount = ref(0);
	let selectedGroupsIds = ref([]);
	let selectedGroupNames = ref('');
	let isSelectAllGroups = ref(false);
	let isSelectAllContacts = ref(false);
	let selectedContactIds = ref([]); //{UserID:400}, {UserID:401}
	let selectedContactNames = ref('');
	
	let isNewMessage = ref(true);
	let groupTypesList = [];
	const instance = getCurrentInstance();
	const route = useRoute();
	const groupListRef = ref(null);
	let emits = defineEmits(['group-selection-saved'])
	function showEditModal(){
		showAboutModal.value = true;
	}
	function onSelectionSaved(receivers){
		//console.log("receivers :: ", receivers)
		let messageGroups = receivers.selectedGroups;
		let receiverCount = 0;
		//if Groups are selected
		if(messageGroups.selectedItem){
			//Assuming the perpage is 5, so if selection is less than 6, and even the isSelectedAll is checked, we will not go to fetch
			isSelectAllGroups.value = messageGroups.isSelectAll && messageGroups.uncheckedItems.length == 0;
			
			if (!messageGroups.isSelectAll){
				//new code
				messageGroups.checkedItems.map((g)=>{
					if(g.Status == "active"){
						receiverCount += g.Member_Count;
					}
				})
				selectedGroupsCount.value = receiverCount;
				selectedGroupsIds.value = messageGroups.checkedItems.filter(obj => obj.Status == "active");
				selectedGroupNames.value = messageGroups.checkedItems.filter(obj => obj.Status == "active").map(obj=>getGroupNameTypeCount(obj)).map(obj=> obj.details).join(", "); 

				saveContactSelection(receivers);
			} else {
				let url = API_URL + "groups/?CreatedBy=" + instance.proxy.$root.userdetail.user[0].UserID;
				url += "&ClientID=" + instance.proxy.$root.userdetail.user[0].ClientID;
				fetch(url, {
					method: "GET",
					headers: {
					"content-type": "application/json",
					Authorization: "Bearer " + localStorage.getItem("tknds"),
					},
				})
				.then((response) => response.json())
				.then((result) => {
				//Auth Token Failed
				if (result.AuthSuccess == 0) {
						instance.proxy.serverError = result.message;
						instance.proxy.$root.logout();
				} else {
					result.data.map((g)=>{
					if(g.Status == "active"){
						receiverCount += g.Member_Count;
					}
					})
					selectedGroupsCount.value = receiverCount;
					selectedGroupsIds.value = result.data.filter(obj => obj.Status == "active");
					selectedGroupNames.value = result.data.filter(obj => obj.Status == "active").map(obj=>getGroupNameTypeCount(obj)).map(obj=> obj.details).join(", "); 
					saveContactSelection(receivers);
				}
			})
			.catch((e) => {
			console.log(e);
			});
		} 
	} else {
		selectedGroupsIds.value = [];
		selectedGroupNames.value = "";
		isSelectAllGroups.value = false;
		selectedGroupsCount.value = 0;
		saveContactSelection(receivers);
	}
}

function saveContactSelection(receivers){
	let messageContacts = receivers.selectedContacts;
	let receiverCount = selectedGroupsCount.value;
	//alert(messageContacts.selectedItem)
	if (messageContacts.selectedItem) {
		isSelectAllContacts.value = messageContacts.isSelectAll && messageContacts.uncheckedContacts.length == 0;
		if (!messageContacts.isSelectAll) {
				//new code
				receiverCount += messageContacts.checkedContacts.length;
				selectedGroupsCount.value = receiverCount;
				
				selectedContactIds.value = messageContacts.checkedContacts;
				selectedContactNames.value = messageContacts.checkedContacts.map((obj)=> obj.FirstName +" "+ obj.LastName).join("; "); 
				//Dispatch Event
				//console.log()
				emits('receivers-selection-saved', 
					{"group_ids": selectedGroupsIds.value.map(n=> n.Id).join(","),
					 "contact_ids": selectedContactIds.value.map(n=> n.UserID).join(","),
					 "groups_all" : isSelectAllGroups.value,
					 "contacts_all" : isSelectAllContacts.value,
					 "group_names": selectedGroupNames.value,
					 "contact_names": selectedContactNames.value,
					 "groupObjs": selectedGroupsIds,
					 "contactObjs": selectedContactIds
					})
			} else {
				let url = API_URL + "teams/?CreatedBy=" + instance.proxy.$root.userdetail.user[0].UserID;
				url += "&ClientID=" + instance.proxy.$root.userdetail.user[0].ClientID;
				url += "&perpage=10000";
				fetch(url, {
					method: "GET",
					headers: {
					"content-type": "application/json",
					Authorization: "Bearer " + localStorage.getItem("tknds"),
					},
				})
				.then((response) => response.json())
				.then((result) => {
				//Auth Token Failed
				if (result.AuthSuccess == 0) {
						instance.proxy.serverError = result.message;
						instance.proxy.$root.logout();
				} else {
					
					selectedContactIds.value = result.teams.docs.filter((c)=>{
						if(messageContacts.uncheckedContacts.indexOf(c.UserID) == -1){
							receiverCount ++;
							return c;
						}
					});
					selectedContactNames.value = selectedContactIds.value.map(obj=> obj.FirstName +" "+ obj.LastName).join("; ");
					//Dispatch Event
					emits('receivers-selection-saved', 
						{"group_ids": selectedGroupsIds.value.map(n=> n.Id).join(","),
						"contact_ids": selectedContactIds.value.map(n=> n.UserID).join(","),
						"groups_all" : isSelectAllGroups.value,
						"contacts_all" : isSelectAllContacts.value,
						"group_names": selectedGroupNames.value,
						"contact_names": selectedContactNames.value,
						"groupObjs": selectedGroupsIds,
						"contactObjs": selectedContactIds
						})
				}
			})
			.catch((e) => {
			console.log(e);
			});
		
		}
	} else {
		selectedContactIds.value = [];
		selectedContactNames.value = "";
		isSelectAllContacts.value = false;
		emits('receivers-selection-saved', 
				{"group_ids": selectedGroupsIds.value.map(n=> n.Id).join(","),
				"contact_ids": selectedContactIds.value.map(n=> n.UserID).join(","),
				"groups_all" : isSelectAllGroups.value,
				"contacts_all" : isSelectAllContacts.value,
				"group_names": selectedGroupNames.value,
				"contact_names": selectedContactNames.value,
				"groupObjs": selectedGroupsIds,
				"contactObjs": selectedContactIds
				})
	}

}


function getGroupNameTypeCount(group){
	group['details'] = group.Name + " (" + group.Group_Type + " / " + group.Member_Count + ")";
	return group;
}

function createDefaultContact(){
		let operator = instance.proxy.$root.userdetail.user[0];
		let url = API_URL + "createdefaultcontact"
		fetch(url, {
			method: "POST",
			body: JSON.stringify(operator),
			headers: {
			"content-type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("tknds"),
			},
		})
			.then((response) => response.json())
			.then((result) => {
			console.log('default contact', result)
			selectedGroupsCount.value = 1;
			isSelectAllGroups.value = false;
			selectedGroupsIds.value = [];
			selectedGroupNames.value = "";

			isSelectAllContacts.value = false;
			selectedContactIds.value = result.data;
			selectedContactNames.value = result.data[0].FirstName + " " + result.data[0].LastName +";";
			
			emits('receivers-selection-saved', 
				{"group_ids": "",
				"contact_ids": selectedContactIds.value.map(n=> n.UserID).join(","),
				"groups_all" : isSelectAllGroups.value,
				"contacts_all" : isSelectAllContacts.value,
				"group_names": selectedGroupNames.value,
				"contact_names": selectedContactNames.value,
				"groupObjs": selectedGroupsIds,
				"contactObjs": selectedContactIds
				})
				isNewMessage.value = false;
						
			})
			.catch((e) => {
			console.log(e);
			});
}

function getGroupTypes() {
      fetch(API_URL + "group_type", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tknds"),
        },
      })
        .then((response) => response.json())
        .then((result) => {
          groupTypesList = result.map((e) => {
            return { label: e.Name, value: e.Id, isIcon: true, icon: e.Icon };
          });
          groupTypesList.unshift({
            label: "Select",
            value: "",
            isIcon: false,
            show: true,
          });
        });
      
}

function getContacts() {
      let url = API_URL +
        "teams/?CreatedBy=" + instance.proxy.$root.userdetail.user[0].UserID+"&DefaultContact=1";
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tknds"),
        },
      })
        .then((response) => response.json())
        .then((result) => {
			console.log()
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            //this.serverError = result.message;
            instance.proxy.$root.logout();
          } 
		  
		  if(result.teams.docs.length == 0){
			createDefaultContact();
		  } else {
			selectedGroupsCount.value = 1;
			isSelectAllGroups.value = false;
			isSelectAllContacts.value = false;
			selectedGroupsIds.value = [];
			selectedContactIds.value = [result.teams.docs[0]]
			selectedGroupNames.value = "";
			selectedContactNames.value = result.teams.docs[0].FirstName + " "+ result.teams.docs[0].LastName + ";";
			emits('receivers-selection-saved', 
						{"group_ids": selectedGroupsIds.value.map(n=> n.Id).join(","),
						"contact_ids": selectedContactIds.value.map(n=> n.UserID).join(","),
						"groups_all" : isSelectAllGroups.value,
						"contacts_all" : isSelectAllContacts.value,
						"group_names": selectedGroupNames.value,
						"contact_names": selectedContactNames.value,
						"groupObjs": selectedGroupsIds,
						"contactObjs": selectedContactIds
						})
				isNewMessage.value = false;
		  } 
		  
        })
        .catch((e) => {
          console.log(e);
        });
    }

onMounted(()=>{
	getGroupTypes();

	if(route.query.id){
		isNewMessage.value = false;
		let url = API_URL + "getmessagegroups/?message_id=" + route.query.id+"&createdBy="+instance.proxy.$root.userdetail.user[0].UserID;
			fetch(url, {
				method: "GET",
				headers: {
				"content-type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("tknds"),
				},
			})
        	.then((response) => response.json())
        	.then((result) => {
			//Auth Token Failed
			
			if (result.AuthSuccess == 0) {
					instance.proxy.serverError = result.message;
					instance.proxy.$root.logout();
			} else {
				
				console.log("onMountedresult :: ", result)
				let receiverCount = 0;
				//alert(result.groups.length +" :: "+result.contacts.length)
				if ((result.data.groups && result.groups.length > 0) || (result.data.contacts && result.contacts.length > 0)){
					//console.log("result.groups :: ", result.groups)
					result.groups.map((g)=>{
						receiverCount += g.Member_Count;
					})
					//alert('hey1')
					receiverCount += result.data.contacts.length;
					selectedGroupsCount.value = receiverCount;
					isSelectAllGroups.value = result.data.groups_selected_all == 1;
					isSelectAllContacts.value = result.data.contacts_selected_all == 1;
					selectedGroupsIds.value = result.groups;
					selectedContactIds.value = result.contacts;
					selectedGroupNames.value = result.groups.map(obj=>getGroupNameTypeCount(obj)).map(obj=> obj.details).join(", ");
					selectedContactNames.value = result.contacts.map(obj=> obj.FirstName +" "+ obj.LastName).join("; "); 
					//alert('here we are')
					emits('receivers-selection-saved', 
						{"group_ids": selectedGroupsIds.value.map(n=> n.Id).join(","),
						"contact_ids": selectedContactIds.value.map(n=> n.UserID).join(","),
						"groups_all" : isSelectAllGroups.value,
						"contacts_all" : isSelectAllContacts.value,
						"group_names": selectedGroupNames.value,
						"contact_names": selectedContactNames.value,
						"groupObjs": selectedGroupsIds,
						"contactObjs": selectedContactIds
						})
				}
			}
        })
        .catch((e) => {
          console.log(e);
        });
	} else {
		getContacts();
	}
})



	

</script>

<style lang="scss" scoped>

.form-group {
	display: flex;
	align-items: center;
}
.form-label {
	min-width: 90px;
}

.form-control {
    padding: 13px 13px;
    height: 44px;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
		width: 227px;
		position: relative;
}

.edit-icon-button {
		display: block;
    position: absolute;
    right: 5px;
    top: 6px;
}
 
	

</style>