<template>
  <div class="align-center justify-center super-admin-table">
      <div class="selected_row_box">
      <div class="d-flex justify-content-center position-relative">
        <div class="bulk_select">
          <label>3 Selected</label>
          <label class="link"><IconDeArchieve /><span>Reactivate</span></label>              
        </div>
      </div>
    </div>
     <S2gTable
       :indeterminate="indeterminate"
       :items="clients"
       :selectedItemCount="selectedContacts"
       ref="tableRef"
       :isWhiteIcons="true"
       :headers="clientHeader"
       :filterItems="[
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
       ]"
       
       @toggle-row-select="selectMembers"
       :thsearch="false"
       :showCount="true"
       :isReload="true"
       :totalItems="1000"
       :totalCountText="'Clients'"
       :totalCountText1="'Contact'"
       :dynamicSearchHeight="false"
       :isSelectGlobalCheckBox="isSelectAll"
       @select-all="selectAll"
       :page="0"
     >
       <template v-slot:table_body="{ item }">
         <td>
           <div>{{ item.item.name }}</div>
         </td>
         <td>
          <div>{{ item.item.companyName }}</div>
          <!-- <div class="filter-cell"><span></span> {{ item.item.companyName }}</div> -->
         </td>
         <td>
          <div>{{ item.item.signUpDate }}</div>
         </td>
         <td>
            <div>{{ item.item.lastUsed }}</div>
         </td>
          
         <td style="max-width: 210px; width: 210px">
           <div class="table-actions">
             <SettingDots/>
             <span class="icon-btn">
               <IconView/>
             </span>
             <span class="icon-btn">
               <EditIcon/>
             </span>
             <span class="icon-btn">
               <IconDeArchieve/>
             </span>
           </div>
         </td>
       </template>
     </S2gTable>
     
   </div>

    
   
</template>

<script setup>
 
 
import S2gTable from "@/components/common/S2gTable.vue";
import SettingDots from '@/components/icons/message_settings/SettingDots.vue';
import EditIcon from '@/components/icons/EditIcon';
import IconView from '@/components/icons/IconView';
import IconDeArchieve from '@/components/icons/IconDeArchieve';


const clientHeader= [
     { key: "name", title: "Name",},
     {
       key: "companyName",
       title: "Company Name",
       class: "pr-0",
       isFilter: false,
       filterItems: [
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
         { label: 'Pending', value: 'pending' },
         { label: 'Verified', value: 'verified' },
         { label: 'Invite', value: 'invite' },
       ],
     },
     {
       key: "signUpDate",
       title: "Sign up date",
       class: "pr-0",
       isFilter: false,
       filterItems: [
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
         { label: 'Pending', value: 'pending' },
         { label: 'Verified', value: 'verified' },
         { label: 'Invite', value: 'invite' },
       ],
     },
     {
       key: "lastUsed",
       title: "Last used",
       class: "pr-0",
       isFilter: false,
       filterItems: [
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
         { label: 'Pending', value: 'pending' },
         { label: 'Verified', value: 'verified' },
         { label: 'Invite', value: 'invite' },
       ],
     },
     { key: "details", title: "Details" },
   ]

 let clients = [{
    name : "Client Name",
    companyName: "Red Feather Property Management LLC.",
    signUpDate: "Sep 17, 2024",
    lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    }
  ]

 let indeterminate  = false;

 function selectMembers(e){

 }

 let isSelectAll = false;
</script>

<style lang="scss">
  .super-admin-table {
    padding-top: 78px;
    position: relative;
    & .mr-70 {
      margin-right: 125px !important;
      }
    & .search_container, .selected_row_box {
      margin-bottom: 0;
      position: absolute;
      top: 9px;
      width: 100%;
    }
  }
</style>
