<template>
    <div>
        <v-menu :disabled="disabled" v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ props }">
                <div v-bind="props" style="text-align:center">
                    <SettingDots/>
                </div>
            </template>
            <v-card class="edit-settings-modal">
                <v-card-title>
                    <span class="dialog-title">{{contact.FirstName}} {{ contact.LastName }}</span>
                </v-card-title>
                <div class="gc-wrapper" v-if="getContactGroups().length > 0">
                    <div class="gc-row" >
                        <div class="gc-line" v-for="(item, index) in getContactGroups()" v-bind:class="(index > 3 && !moreclicked)?'more-groups':''" :key="item.left">
                        <component :index="index" :is="item.left?.groupIcon"></component>
                        {{ item.left ? item.left.groupName : "" }}
                        </div>
                    </div>

                    <div class="gc-row" >
                        <div class="gc-line" v-for="(item, index) in getContactGroups()" v-bind:class="(index > 3 && !moreclicked)?'more-groups':''" :key="item.right">
                            <component index :is="item.right?.groupIcon"></component>
                            {{item.right ? item.right.groupName : ""}}</div>
                    </div>
                </div>
                <div class="gc-wrapper" v-if="getContactGroups().length < 1">
                    <div class="gc-row full-row">
                        <div class="gc-line"><GroupIcon/>Not assigned to a group yet</div>
                    </div>
                </div>
                    
                <div class="gc-footer">
                    <div class="gc-shared-info">
                        <div class="gc-shared-item" v-if="contact.Shared"><SharedByOperator/> Shared by {{ contact.ContactCreator }}</div>
                        <div v-if="!contact.Shared && sharingEnabled && String(contact.ContactCreator).toLocaleLowerCase() == 'you'">
                            <button class="gc-shared-item" @click="shareThisContact(contact)"><ShareBatchIcon/> Share this Contact</button>
                        </div>
                    </div>
                    <button v-if="getContactGroups().length > 4 && !moreclicked" @click="moreclicked=true" class="gc-btn-more" type="button">More...</button>
                </div>
            </v-card>


        </v-menu>
    </div>
  </template>
  
  <script>
    import SettingDots from '../icons/message_settings/SettingDots.vue';
    import CompanyIcon from "@/components/icons/Company";
    import GroupIcon from "@/components/icons/Group";
    import EventIcon from "@/components/icons/Event";
    import PropertyIcon from "@/components/icons/Property";
    import AreaIcon from "@/components/icons/Area";
    import TeamIcon from "@/components/icons/MemberIcon";
    import ProjectIcon from "@/components/icons/Project";
    import LocationIcon from "@/components/icons/Location";
    import SharedByOperator from '@/components/icons/share/SharedByOperator';
    import ShareBatchIcon from '@/components/icons/share/ShareBatchIcon';

    export default{
        components: {
            SettingDots,CompanyIcon,GroupIcon,EventIcon,PropertyIcon,AreaIcon,TeamIcon,ProjectIcon,LocationIcon,SharedByOperator, ShareBatchIcon
        },
        data:()=>{
            return{
                menu:false,
                moreclicked:false
            }
        },
        props:{
            disabled:Boolean,
            contact:{},
            contactGroups:[],
            sharingEnabled:Boolean,
        },
        methods:{
            shareThisContact(contact){
                
                this.$emit('contactShared', contact);
                this.menu = false;
                
            },
            getContactGroups(){
                let allAssingedGroups = this.contactGroups.filter(a => a.Contact_Id == this.contact.UserID);
                allAssingedGroups.sort(function(a,b) {
                    const normalize = str => str.replace(/'/g, ''); // Just remove apostrophes
                    return normalize(a.groupName).localeCompare(normalize(b.groupName));
                });
                //Show 4 in left column and then 4 in right column
                let breakpoint = 0;
                let index = 0;
                let leftIndex = [];
                let tableData = [];
                for (let i in allAssingedGroups){
                    if (index <= allAssingedGroups.length)
                    leftIndex.push(index);
                    breakpoint ++
                    index++
                    if(breakpoint == 4){
                        index += breakpoint;
                        breakpoint = 0;
                    }
                }
                let rightIndex = Array.from({ length: allAssingedGroups.length }, (_, index) => index).filter(i=> !leftIndex.includes(i));
                for(let i in allAssingedGroups){
                    tableData[i] = {"left":false, "right":false};
                    if (leftIndex.length >= i){
                        tableData[i].left = allAssingedGroups[leftIndex[i]];
                    }
                    if (rightIndex.length >= i){
                        tableData[i].right = allAssingedGroups[rightIndex[i]];
                    }
                }
                tableData = tableData.filter(n=> n.left || n.right)

                return tableData;
            }
        }
    }
    
  </script>
<style lang="scss" scoped>
.edit-settings-modal {
        width: 430px;
        height: 300px !important;
        display: flex;
        flex-direction: column; 
    }
.v-card .v-card-title {
    padding: 24px 0 16px 24px;
    line-height: 1;
    font-size: 16px;
    color: #333333;
    font-weight: 500;
}
.more-groups{
    display: none !important;
}
 .gc-wrapper {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  padding: 8px 24px 16px;
  overflow: auto;
  color: #828282;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  height: 192px;
  overflow: auto;
 }
 .gc-row {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &.full-row {
        grid-column: span 2 / span 2;
    }
}
.gc-line {
    display: flex;
    align-items: center;
    height: 24px;
    gap: 19px;
}
.gc-footer {
    display: flex;
    align-items: center;
    padding: 3px 10px 19px 20px;
    justify-content: space-between;
}
.gc-shared-info {
    display: flex;
}
.gc-shared-item {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #828282;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
}
.gc-btn-more {
    color: #4066CF;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
}
</style>