<template>
  <div class="hovered-btn">
 
    <svg class="default" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 15V7" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.4001 10.3L15.0001 7L11.6001 10.4" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25 17.5H19L17 19.5H13L11 17.5H5" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 17.5V22.5C5 23.6 5.9 24.5 7 24.5H23C24.1 24.5 25 23.6 25 22.5V17.5" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

 

 
<svg class="hovered" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9979_82727)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 5C0 2.23858 2.23858 0 5 0H25C27.7614 0 30 2.23858 30 5V25C30 27.7614 27.7614 30 25 30H5C2.23858 30 0 27.7614 0 25V5Z" fill="white"/>
<path d="M15 15V7" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.4001 10.3L15.0001 7L11.6001 10.4" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25 17.5H19L17 19.5H13L11 17.5H5" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 17.5V22.5C5 23.6 5.9 24.5 7 24.5H23C24.1 24.5 25 23.6 25 22.5V17.5" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_9979_82727">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>


 
 
  </div>
</template>