<template>
    <v-dialog v-model="dialog"  max-width="448" min-height="298">
        <v-card>
            <v-toolbar class="modal-header">
                <v-toolbar-title>Confirmation</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="confirmation card-text-con">
                <slot></slot>
            </v-card-text>
            <v-card-actions class="justify-end" style="padding-right:17px;">
                <button @click="close" class="action-btn text-btn text-uppercase">Cancel</button>
                <button @click="confirm" style="margin-left:40px" class="action-btn text-btn text-uppercase">Confirm</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { VDialog, VCard,VToolbarTitle,VToolbar, VCardText, VCardActions } from 'vuetify'

export default {
    name: 'Confirmation',
    props:['value', 'data', 'onconfirm', 'body'],
     
components : {
    VDialog, VCard, VCardText, VToolbar, VToolbarTitle, VCardActions  
},
computed: {
    dialog: {
        get() {
            return this.value;
        },
        set(value) {
            this.$emit('closed', value);
        }
    }
},
methods: {
    close() {
        this.dialog = false;
    }
    ,
    confirm(){
        this.onconfirm(this.data);
    }
}
};
</script>
<style lang="scss" scoped>
   .confirmation.card-text-con{
    padding: 25px 16px 0 16px !important;
   }
   .v-card {
    flex: 1;
   }
   .v-card-actions {
    padding-top: 18px;
    padding-bottom: 18px;
   }
   .v-toolbar__title{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
   }
   .action-btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    transition: 0.2s all ease;
    color: #2F80ED;
    &:hover {
        opacity: 0.62;
    }
   }
</style>