<template>
	<v-row justify="center">
	  <v-dialog
		v-model="dialog"
		persistent
		width="900px">	  	

			<v-form @submit.prevent="updateProfile" ref="entryForm">
				
				<v-card class="modal-con update-modal">		
						<v-row class="modal-row">
							<v-col class="modal-col" cols="4" sm="6" md="6">
								<v-card-title class="p-0 modal-title-con">
									<h5 class="modal-title">{{ title }}</h5>
								</v-card-title>
							</v-col>

							<v-col class="modal-col" cols="8" sm="6" md="6">
							<!-- <v-col class="modal-col" cols="12" sm="6" md="6" style="
									padding-right: 0; margin-top:-26px; margin-bottom: 5px;"> -->
								<v-tabs v-model="tab" class="float-right">
									<v-tab v-if="!Receiver" slim grow value="tabProfile" class="tabs_D_item together">Profile</v-tab>

									<v-tab v-if="!Receiver && (user.PhoneNumber == null || validatePhoneNumber(1) !== true)" 
										slim grow 		
										value="tabTimestamp" 
										class="tabs_D_item together"
										:disabled="true">Timezone
									</v-tab>

									<v-tab v-if="!Receiver && user.PhoneNumber != null && 
												 validatePhoneNumber(1) === true" slim grow value="tabTimestamp" 
												 @click="isTabClicked()" 
												 class="tabs_D_item together">Timezone
									</v-tab>

									<v-tab slim grow value="tabData" @click="isTabClicked()"
									:disabled="(user.PhoneNumber == null || validatePhoneNumber(1) !== true)" class="tabs_D_item together">Data
								</v-tab>
								</v-tabs>
							</v-col>
						</v-row>	
			 
			
				
			<v-card-text class="modal-content-con p-0">					
					<v-window v-model="tab">
						<!-- Profile Tab Start-->
						<v-window-item class="tab-profile" value="tabProfile">
						 
								<v-row class="modal-row">

									<v-col class="modal-col" cols="12" sm="6" md="6">
										<v-text-field
											label="First name*"
											:rules="nameRules_firstname"
											v-model="user.FirstName"
											variant="underlined"
											clearable
											:clear-icon="'mdi-close'"
										></v-text-field>
									</v-col>

									<v-col cols="12" sm="6" md="6" class="modal-col">
										<v-text-field
											label="Last name*"
											:rules="nameRules_lastname"
											v-model="user.LastName"
											variant="underlined"
											clearable
											:clear-icon="'mdi-close'"
										></v-text-field>
									</v-col>


									<v-col class="modal-col" cols="12" sm="6" md="6">
										<v-text-field
											class="phone-input-pupup"
											label="Phone number*"
											v-model="user.PhoneNumber"
											:rules="phoneRules"
											type="text" 
											@keypress="isPhoneNumber($event)"
											variant="underlined"
											clearable
											:clear-icon="'mdi-close'"
										>
										
										<vue-tel-input :value="PhoneNumber1" 
														:onlyCountries="selectedCountries"   @open="CountrySelectOpen" @close="CountrySelectClose"  :input-id="'phone'" @input="onPhoneInput">
										</vue-tel-input>
											<span class="countryCodePopup" style="font-weight: 600;">+{{ user.countryCode }}</span>
										</v-text-field>
									</v-col>

									<v-col v-if="Receiver" cols="12" sm="6" md="6" class="modal-col">
										<v-text-field
											label="Email"
											v-model="user.Email"
											variant="underlined"
											clearable
											:clear-icon="'mdi-close'"
										></v-text-field>
									</v-col>


								<v-col cols="12" sm="6" md="6" v-if="!serverError">
									<!-- Notification for first time User Mobile No. filled and < 10 -->
									<div class="v_notification" 
												v-if="Receiver == undefined && user.PhoneNumber != null && 
												validatePhoneNumber(1) !== true && 
												(this.$root.userdetail.user[0].PhoneNumber == null || 
												this.$root.userdetail.user[0].PhoneNumber == '')">
											{{ notification_msg }}

									</div>

								 
									

									<!-- Notification for first time User Mobile No = null -->
									<div class="v_notification"
										v-if="Receiver == undefined && user.PhoneNumber == null && 
											(this.$root.userdetail.user[0].PhoneNumber == null || 
												this.$root.userdetail.user[0].PhoneNumber == '')">	
										{{ notification_msg }}
										
									</div>

									

									<!-- First time User Timezone click -->
									<div class="v_notification notof_01" 
											v-if="user.PhoneNumber != null && Receiver == undefined &&
											validatePhoneNumber(1) === true && tabclicked == false &&
											(this.$root.userdetail.user[0].PhoneNumber == null || 
												this.$root.userdetail.user[0].PhoneNumber == '')">	
										{{ timezoneError }}
									</div>
									

									<div v-if="user.PhoneNumber != null && Receiver == undefined">
											<div v-if="validatePhoneNumber(1) === true">
											<div class="v_notification notof_01" 
											v-if="tabclicked && 
												 (this.$root.userdetail.user[0].TimeZone == '' || 
												  user.selectedTimezone == '')">
												  {{ timezoneError }}
											</div>
										</div>
									</div>

											
												<!-- <div v-if="user.PhoneNumber != null && 
															Receiver == undefined">
														<div v-if="user.PhoneNumber.length >= 10">
														<div class="v_notification notof_01" 
														v-if="tabclicked && (this.$root.userdetail.user[0].TimeZone == '' || user.selectedTimezone == '')">
															{{ timezoneError }}
														</div>
													</div>
												</div> -->
									
									
									

											
												<!-- <div class="v_notification notof_01"												
													v-if="user.PhoneNumber != null && user.PhoneNumber.length == 10
														&& Receiver == undefined && user.selectedTimezone == ''">
														{{ timezoneError }}									
												</div> -->
										</v-col>

										<v-col cols="12" sm="6" md="6" v-if="serverError">
											<div class="servererror">{{serverError}}</div>
										</v-col>

										<!-- </v-col> -->
									</v-row>
							 
							</v-window-item>	
							<!-- Profile Tab End-->

							<!-- Timestamp Tab Start-->
							<v-window-item value="tabTimestamp">
								<div class="timez_p">
									<div class="tz_lable">This is for the Message timestamp your <i>Receivers</i> will see.</div>
									<VueSelect
										v-model="user.selectedTimezone"
										:options="tzObj"
										:rules="nameRules_timezone"
										placeholder="Select Timestamp"
									/>

									<div class="v_notification notof_tze" 
											v-if="!Receiver && !user.selectedTimezone">
												{{ timezoneError }}									
									</div>
								</div>
								
							</v-window-item>
							<!-- Timestamp Tab End-->

							<!-- Data Tab Start-->
							<v-window-item value="tabData">
								<div class="data-grid-group">
									<div class="data-grid" v-if="dataTabData?.operator != undefined">
										<h3>Operators</h3>
										<DataTabOperator />
										<h6>{{ dataTabData?.operator ?? 0 }}</h6>
									</div>
									<div class="data-grid" v-if="dataTabData?.contact != undefined">
										<h3>Contacts</h3>
										<DataTabContacts />
										<h6>{{ dataTabData?.contact ?? 0 }}</h6>
									</div>
									<div class="data-grid" v-if="dataTabData?.group != undefined">
										<h3>Groups</h3>
										<DataTabGroup />
										<h6>{{ dataTabData?.group ?? 0 }}</h6>
									</div>
									<div class="data-grid" v-if="dataTabData?.message != undefined">
										<h3>Messages</h3>
										<DataTabMessage />
										<h6>{{ dataTabData?.message ?? 0 }}</h6>
									</div>
								</div>

							</v-window-item>
							<!-- Data Tab End-->
						</v-window>	
					<!-- <small>*indicates required field</small> -->
				</v-card-text>

				<!-- https://github.com/RomainSimon/vue-simple-search-dropdown?tab=readme-ov-file -->
				
				<v-card-actions class="modal-actions p-0">
					<span v-if="!Receiver" class="email_c">
						{{ userEmail }}
					</span>					
					<v-spacer></v-spacer>

					<div class="button-group" v-if="(user.PhoneNumber != null && validatePhoneNumber(1) === true && user.FirstName && user.LastName && (this.tabclicked || this.$root.userdetail.user[0].TimeZone)) || Receiver">
							<button 
							class="action-btn text-btn font-roboto text-decoration-none text-uppercase"
							:class="{ 'disabled1': !this.$root.userdetail.user[0].PhoneNumber }"
							variant="text"
							@click="dialogClosed"
							plain
							:disabled="!this.$root.userdetail.user[0].PhoneNumber"
							>
							CLOSE
							</button>

						<button v-if="(user.PhoneNumber == null || validatePhoneNumber(1) !== true || user.FirstName == null || user.LastName == null)" 
							class="action-btn text-btn text-uppercase disabled1"
							variant="text"
							type="submit"
							:disabled="true" 
							plain				
						>
						SAVE
						</button>


					<button v-if="(user.PhoneNumber != null && validatePhoneNumber(1) === true && 
								  user.FirstName != null && user.LastName !=null)" 
						class="action-btn text-btn text-uppercase"
						variant="text"
						type="submit" 
						plain		
					>
					SAVE
					</button>
					</div>
					
				</v-card-actions>
				
				</v-card>
			</v-form>
	  </v-dialog>
	</v-row>
  </template>

<script>
import { ref } from 'vue';
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import timezonelist from '../timezone.json'
import VueSelect from 'vue3-select-component'
import 'vue3-select-component/dist/style.css' 
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { VDialog, VBtn, VCard, VRow, VCardTitle, VCardText, VTextField, VCardActions, VCol, VSpacer, VForm } from 'vuetify'
import userService from '@/services/userService';


const option = ref("");


export default {
	props: ['title', 'TogglePopup', 'ProfileSaved', 'Notification', 'Receiver', 'meg_notice'],
	components : {
		VDialog,  VCard, VRow,  VCardTitle, VCardText, VTextField, VCardActions, VCol, VSpacer, VForm, VueTelInput, VueSelect
	},
	 computed:{
		PhoneNumber1:{
			get(){ 
				if (!this.user.countryCode){
					if (this.user.PhoneNumber){
						return "+"+ this.user.PhoneNumber 
					}else {
						return '';
					}
				} else {
					if (this.user.PhoneNumber){
						return "+"+this.user.countryCode + this.user.PhoneNumber;
					} else {
						return '';
					}
				}
			},
			set(val){}
		},
	},
	
	beforeMount() {
		this.timezoneObject();
		this.defaultSelectedTimeZone();	
	},
	mounted(){	
		
		if (this.Receiver){
			this.user.FirstName = this.Receiver.FirstName;
			this.user.LastName = this.Receiver.LastName;
			this.user.Email = this.Receiver.Email;
			this.user.PhoneNumber = String(this.Receiver.PhoneNumber);
			this.user.UserID = this.Receiver.UserID;
			this.user.UserTable = 'ClientUsers';
		} else {
			let user = this.$root.userdetail.user[0];
			//console.log("Root User: ");
			//console.log(user);
			this.user.FirstName = user.FirstName ? user.FirstName : '';
			this.user.LastName = user.LastName ? user.LastName : '';
			this.user.Email = user.Email ? user.Email : '';
			this.user.PhoneNumber = this.initialPhoneNumber = user.PhoneNumber;
			this.user.UserID = user.UserID;
			this.user.UserTable = 'SaasUsers';

			this.user.selectedTimezone = this.selectedTimezone ? this.selectedTimezone : '';

			//console.log("Local Data: ")
			//console.log(this.user);		
			
		}

		if(this.user.PhoneNumber == '')
		{
			this.isDisabledTab = true; 
		}else{
			this.isDisabledTab = false; 
		}

				
		//this.PhoneNumber1 = "+"+this.user.PhoneNumber;

		if(this.user.PhoneNumber && this.user.PhoneNumber.length > 10){
			this.PhoneNumber1 = "+"+this.user.PhoneNumber;
		}else {
			this.user.PhoneNumber = '';
			
		}
		
		/* alert('hi')
		if (this.user.PhoneNumber.indexOf('+') == -1){
			this.user.PhoneNumber1 = '+'+this.user.PhoneNumber;
		} */
		
		//console.log(this.$root.userdetail.user[0].TimeZone);
		console.log("Notification: ");
		console.log(Notification);
		//console.log(this.user.countryCode);
		console.log("Receiver check: "+this.Receiver);
		console.log("Root:Mo:" + this.$root.userdetail.user[0].PhoneNumber);
		console.log("TabClicked: "+this.tabclicked);
		//console.log(this.defaultSelectedTz.text);
		this.getUserProfileData();
		
	},
	data() { return {
		token: localStorage.getItem("tknds") || '',
		dialog: true,
		tabclicked: false,
		dataTabData: {},
		errors:{},
		serverError:"",
		tab: null,
		$reset: false,
		selectedTimezone:'',
		timezoneError:'Please select the timezone that your Receivers are in.',
		notification_msg: 'Please enter your phone number to preview and hear the messages you set, to make sure they will play correctly.',
		tz_list: timezonelist,
		userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		defaultSelectedTz:'',
		option: '',
		tzObj: [],
		btn_disable: 0,
		alertMessage: '',
		initialPhoneNumber: '',
		userEmail: this.$root.userdetail.user[0].Email ? this.$root.userdetail.user[0].Email :'',
		user: {
			FirstName: '',
			LastName:'',
			Email:'',
			PhoneNumber:'',
			UserID:'',
			UserTable: '',
			countryCode: '',
			selectedTimezone: ''
		},
		nameRules_firstname: [
			v => !!v || 'First name is required'
		],
		nameRules_lastname: [
			v => !!v || 'Last name is required'
		],
		nameRules_email: [
	      v => !!v || 'Email field is required',
	      v => /.+@.+\..+/.test(v) || 'Email must be valid'
	    ],
		nameRules_timezone:[
			v => !!v || 'Timezone is required'
		]
		,
		phoneRules: [
			v => !!v || 'Phone number is required',
			v => this.validatePhoneNumber(v)
			/* v => {
				
				if (this.user.countryCode == ""){
					return 'Country selection is required';
				}
				if (v){
					//if(v.length < 10 || v.length > 12){
					if(v.length < 10){
						return 'Phone Number is invalid';
					}
				}				
				return true;
			} */
		],
		selectedCountries: ['IN', 'JP', 'US', 'CA', 'PL', 'PH', 'UY', 'CH', 'CZ', 'RS', 'VN', 'PT', 'GB', 'NL', 'FR', 'HR', 'UA', 'ME']
	}},
	methods : {		
		isTabClicked(){
			console.log("Timezone tab Clicked");
			this.tabclicked = true;
		},
		async getUserProfileData() {
			this.dataTabData = await userService.getUserProfileData(this.user?.UserID);
		},
		timezoneObject(){
			//format: { label: 'A Wizard of Earthsea', value: 'wizard_earthsea' }
			for (let todo of this.tz_list) {
				let obj = {}
				obj.label = todo.text
				obj.value = todo.utc[0]
				this.tzObj.push(obj);
			}
		},
		findUserTimezone(data){
			if( JSON.stringify(data).indexOf(this.userTimezone) > -1 ) {
				return data;
			}			
		},
		defaultSelectedTimeZone(){
			if(this.userTimezone != ''){
				this.defaultSelectedTz = this.tz_list.find(this.findUserTimezone); // return object			
			}
			if(this.defaultSelectedTz.text){
				
				if(this.$root.userdetail.user[0].TimeZone == '' || this.$root.userdetail.user[0].TimeZone == undefined){
					this.selectedTimezone = this.defaultSelectedTz.utc[0];
				}else{
					this.selectedTimezone = this.$root.userdetail.user[0].TimeZone;
				}
				//this.selectedTimezone = this.defaultSelectedTz.text;
			}
		},
		isActive(path) {
            return ref(this.$router.currentRoute).value.path === path
        },

		async updateProfile(e){
			let formValidated = await this.$refs.entryForm.validate();

			console.log(this.user.selectedTimezone);
			console.log(this.selectedTimezone);

			// Timezone validation
			if(this.user.selectedTimezone == '' && !this.Receiver){
				this.timezoneError = 'Please select the timezone that your Receivers are in.';
				return false;
			}
			if(!this.$root.userdetail.user[0].PhoneNumber && this.tabclicked == false && !this.Receiver){
				this.timezoneError = 'Please select the timezone that your Receivers are in';
				return false;
			}
			
			if(formValidated.valid){
				this.user.sendAppDownloadSMS = false;
				
				if (this.user.countryCode +""+ this.user.PhoneNumber != this.initialPhoneNumber && !this.Receiver){
					this.user.sendAppDownloadSMS = true;
				} 
				//let api = (this.Receiver) ? process.env.VUE_APP_APIDOMAIN+'/saveclientporfile' : process.env.VUE_APP_APIDOMAIN+'/saveporfile'
				//console.log(api);
/* 				console.log("Data Post: ");
				console.log(this.user); */
				let phoneNumber = this.user.countryCode + "" + this.user.PhoneNumber
				document.querySelector(".countryCodePopup").style.display = "none";
				
				fetch(process.env.VUE_APP_APIDOMAIN+'/saveporfile', {
					method: "POST",
					body: JSON.stringify({...this.user, PhoneNumber: phoneNumber}),
					headers: {
					"content-type": "application/json",
					"Authorization": "Bearer "+this.token
					}
				}).then(response => response.json())
					.then(result => {
						if(result.AuthSuccess == 0){
							this.serverError = result.message
							this.$root.logout();													           
						}
						//console.log(result);
						if (!result.success) {
						// there was an error...
							this.serverError = result.message
						} else {
							
							this.error = "";

							//Fix phone number 
							// strip +,-
							/* this.user.PhoneNumber = this.user.PhoneNumber.split('-').join('').split('+').join('').split(',').join('');

							// if phone length is 10, add 1 as country code
							if ( this.user.PhoneNumber.length == 10 ) {
								this.user.PhoneNumber = "1"+this.user.PhoneNumber;
							}  */

							this.user.PhoneNumber = this.user.countryCode + "" + this.user.PhoneNumber;

							if (!this.Receiver) {
								this.$root.userdetail.user[0].FirstName = this.user.FirstName;
								this.$root.userdetail.user[0].LastName = this.user.LastName;
								this.$root.userdetail.user[0].Email = this.user.Email;
								this.$root.userdetail.user[0].PhoneNumber = this.user.PhoneNumber;
								this.$root.userdetail.user[0].TimeZone = this.user.selectedTimezone;
								this.$root.loggedInUser = this.user.FirstName + " " + this.user.LastName;
							}							
							this.ProfileSaved(this.user);
							//this.TogglePopup('buttonTrigger');							
						}
				});
			} 
			
		},
		isPhoneNumber(evt) {
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
			const keyPressed = evt.key;
			
			if (!keysAllowed.includes(keyPressed) || evt.target.value.length > 9) {
				evt.preventDefault()
			}					
		},
		validatePhoneNumber(v) {
			//console.log(this.user)
			//return this.user.countryCode+ this.user.PhoneNumber+'correct phone number '+ this.iso2;
			const phoneNumber = parsePhoneNumberFromString(this.user.countryCode+ this.user.PhoneNumber, this.iso2);
			if (phoneNumber && phoneNumber.isValid()) {
				return true;
			} else {
				return 'Phone Number is invalid';
			}
		},
		dialogClosed(e){
			this.dialog = false;
			if (typeof this.TogglePopup != "undefined"){
				this.TogglePopup();
			}
			
		},
		onPhoneInput(phone, phoneObject, input) {
			if (typeof phoneObject.country != "undefined"){
				this.iso2 = phoneObject.country.iso2;
			}
			
			let countryCallingCode = "";
			if (typeof phoneObject.countryCallingCode != "undefined"){
				countryCallingCode = phoneObject.countryCallingCode;
			}  else if (typeof phoneObject.country != "undefined"){
				countryCallingCode = phoneObject.country.dialCode;
			} 
			
			if (countryCallingCode){
				let splitcode = this.user.countryCode ? this.user.countryCode : countryCallingCode;
				if (this.user.countryCode == "" && typeof phoneObject.number != "undefined"){
					this.user.PhoneNumber = phoneObject.number.substring(splitcode.length+1);
				}/*  else {
					if (this.user.countryCode != phoneObject.countryCallingCode){
						//this.user.PhoneNumber = "";
					}
				} */
				this.user.countryCode = countryCallingCode;
			}
			setTimeout(function(){
				if (!document.querySelector(".countryCodePopup")){
					return;
				}
				/* var labelElement = document.querySelector(".phone-input-pupup .v-field-label:not(.v-field-label--floating)");
				var left = document.querySelector(".countryCodePopup").offsetLeft + document.querySelector(".countryCodePopup").offsetWidth + 10;
				labelElement.style.marginLeft =  left + "px";
				labelElement.style.display =  "block"; */
			}, 500)
		},
		CountrySelectOpen(){
			document.querySelector(".phone-input-pupup .v-input__details .v-messages").style.display = 'none';
		} ,
		CountrySelectClose(){
			document.querySelector(".phone-input-pupup .v-input__details .v-messages").style.display = 'block';
		}
	}
}
</script>

<style lang="scss">
	.phone-input-pupup {
		& .v-field-label:not(.v-field-label--floating){
      margin-top:-20px;
    } 
	}
		

	.tab-profile {
		padding: 76px 0;
	}

	
 
	.v_notification {
	width: 100%;
	background: #D6FCC4;
	padding: 20px 23px 20px 27px;
	font-size: 14px;
	border-radius: 10px;
	font-size: 14px;
	line-height: 16.41px;
	color: #333333;
	min-height: 80px;
	display: flex;
	align-items: center;
	position: relative;
	top: 10px;
	margin-top: -10px;
}

	 

	.servererror{
			width: 100%;
			background: red;
			padding: 15px;
			border-radius: 10px;
	}
	

	.modal-actions {
		display: flex;
		@media screen and (max-width: 599px) {
			flex-direction: column;
			gap: 10px;
		}
	}

	.button-group {
		display: flex;
		align-items: center;
		gap: 40px;
		@media screen and (max-width: 599px) {
			gap: 20px;
		}
		& .action-btn{
			color: #2F80ED;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			span{
				opacity: 1 !important;
			}
		}
	}



	.v-input.error--text {
		color: #B10000 !important;
        caret-color: #B10000 !important;
    	.v-input__control {
			.v-text-field__details > .error--text{
				color: #B10000 !important;
				caret-color: #B10000 !important;
			}
			.v-input__slot{
				&:before{
					color: #B10000 !important;
					caret-color: #B10000 !important;
					border-width:2px 0 0;
				}
				&:after{
					color: #B10000 !important;
					caret-color: #B10000 !important;
					border-width:2px 0 0;
				} 
				.v-text-field__slot > .error--text{
					color: #B10000 !important;
					caret-color: #B10000 !important;
				}
      		}
    	}
    }
	.v-field__overlay{
		background-color: white !important;
	}
	.custom-underlined-field .v-input__control {
		border-color: green;
	}
	.v-application .primary--text {
		color: #1976d2 !important;
		caret-color: #1976d2 !important;
	}

	.vue-tel-input{
		border:0px !important;
	}
	.vti__input{
		display:none !important;
	}
 

	.vti__dropdown-list {
		border: 1px solid #e4e4e7;
		border-radius: 4px;
		padding: 8px 0;
		width: 100%;
			&.below {
				top: 100%;
				height: 122px !important;
				margin-top: 1px;
			}
		}

	.vti__dropdown {
		padding: 0 4px 0 0;
		position: initial;
		&.open, &:hover {
			background-color: #fff;
		}
		
		& .vti__dropdown-item {
			padding: 8px 12px;
			display: flex;
			align-items: center;
			gap: 6px;
			font-size: 14px;
			gap: 6px;
			&.highlighted {
				background-color: #dbeafe;
			}
		}
	}

	 
	.v-tabs--density-default {
		--v-tabs-height: 26px !important;
	}

	.v-tab.v-tab.v-btn {
		color: #000;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px;
		letter-spacing: 0.8px;
		text-transform: capitalize;
		height: 25px !important;
		min-width: 0 !important;
		margin-top: 14px;
		margin-left: 28px;
	}

	.v-btn--slim {
		padding: 0!important;
	}

	.disabled1{
		color:#d7e7fd !important;
		pointer-events: none;
	}

	.v-card {
		overflow: hidden;
	}

	.v-window {
		overflow: initial !important; 
	}

	.menu{
		height: 180px;
		margin: 1px 0 0 0 !important;
		& .menu-option{
			 color: #333333;
			 font-size: 13px;
			 line-height: 16.41px;
			 font-style: normal;
			 font-weight: 400;
		}
	}
 
 
	
.timez_p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 14px;
    line-height: 16.41px;
    font-style: normal;
    font-weight: 400;
		margin-bottom: 115px;
		height: 201px;
    & .tz_lable {
        margin-bottom: 24px;
        text-align: center;
        letter-spacing: 0;
				margin-top: 80px;
    }
    & .vue-select {
        width: 100%;
        max-width: 400px;
        border-radius: 10px;
        & .control {
            min-height: 80px;
            background: #F2F2F2;
            border: none;
            border-radius: 10px;
            padding: 0 12px 0 27px;
						&.focused {
							box-shadow: none;
						}
        }
        & .value-container {
            padding: 0;
        }
        & .indicators-container {
            padding: 0;
        }
        & .dropdown-icon {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            & path {
                fill: #7B8794;
            }
        }
        & .single-value {
					color: #333333;
					font-size: 14px;
					line-height: 16.41px;
					font-style: normal;
					font-weight: 400;
					height: 100%;
        }
    }
}

 


.update-modal {
	 
		& .together{
    	min-width: 0
    }
		& .notof_tze {
			background-color: #E3F1FF;
			width: 100%;
			padding: 20px 23px 20px 27px;
			font-size: 14px;
			border-radius: 10px;
			font-size: 14px;
			line-height: 16.41px;
			color: #333333;
			min-height: 80px;
			align-items: center;
			position: relative;
			top: 10px;
			margin-top: -10px;
		}
		& .notof_01 {
			/* background-color:#E3F1FF; */
			background-color:#344D7A;
			color:#FFFFFF;
		}
}

 



	.deactive_tz{
		color:#e5e5e5 !important;
	}

 
 
	.clear-button{
		display: none !important;
	}

	.update-modal .email_c{
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: #000000;
	}
	.update-modal .v-tab {
		cursor: pointer !important;
		transition: none !important;
	}
	.update-modal .v-tab:hover {
		background-color: transparent !important;
	}
	.update-modal .v-ripple__container {
		display: none !important;
	}
	.tabs_D {
		background-color: red;
	}

	.tabs_D_item .v-btn__overlay{
		background-color: transparent !important;
	}
	.tabs_D_item .v-btn__content{
		padding-bottom: 8px;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 16px;
		color: #000000;
		width: 100%;
	}
 

	.tabs_D_item .v-tab__slider{
		height:4px !important;
	}

	.update-modal .v-tab.v-tab.v-btn{
		margin-top: 0px;
	}
	
	.mdi-close {
		font-size: 24px !important;
		color: black !important;
		opacity:1 !important;
	}

	.vti__flag {
		margin-left: 0;
	}
	

</style>